import { Typography } from "@mui/material";
import { OrderContextType, useCartContext } from "../../../../../system/CartProvider";
import ValeLaunchButton from "../../../../molecules/ValeLaunchButton";
import React from "react";
import { amountToString } from "../../../../utils/stringConversion";
import { FOOT_PADDING } from "../../../../molecules/footer";
import { findMenuItem } from "../../../../utils/orderItemUtils";
import { MenuItemsMap } from "../../../../../vale_common";

export type CheckoutBarProps = {
    onCheckout: () => void;
}

export const shouldReviewOrder = (cart: OrderContextType | null, menuItems?: MenuItemsMap) => {
    if (!cart?.items || cart?.items.length === 0) {
        return false;
    }

    if ( !cart?.totalCents || cart?.totalCents === 0) {
        return false;
    }

    const itemWithValeLabel = cart?.items.find((item) => {
        const menuItem = findMenuItem(menuItems, item.itemTypeId)
        console.log("menuItem : ", menuItem)

        if (menuItem?.hasValeLabel) {
            return true;
        }
        return false;
    });

    return itemWithValeLabel !== undefined;
}

const CheckoutBar: React.FC<CheckoutBarProps> = (props) => {
    const cart = useCartContext();
    if ( cart?.items?.length === 0) {
        return null;
    }
    
    return <div style={{ position: "fixed", backgroundColor: "#7C50FF", bottom: "0", width: "100%", height: FOOT_PADDING, display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
        <div>
            <Typography style={{color: 'white', fontSize: "18", fontWeight: "700", lineHeight: "21px"}}>Your Subtotal</Typography>
            <Typography style={{color: 'white', fontSize: "16", fontWeight: "500", lineHeight: "19px"}}>{amountToString(cart?.totalCents)}</Typography>
        </div>
        <ValeLaunchButton withArrow={true} onPress={props.onCheckout} width={155} fontStyle={{fontSize:18}}>CHECKOUT</ValeLaunchButton>
    </div>
}

export default CheckoutBar;