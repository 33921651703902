import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress, Container, CardMedia } from '@mui/material';
import axios from 'axios';

interface Reward {
  reward_id: string;
  point_cost: number;
  name: string;
  desc: string;
  imageUrl: string;
}

const LoyaltyRewards: React.FC = () => {
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await axios.get('https://api-dev.valenow.com/loyalty/rewards');
        const sortedRewards = response.data.sort((a: Reward, b: Reward) => a.point_cost - b.point_cost);
        setRewards(sortedRewards);
      } catch (error) {
        setError('Failed to fetch rewards.');
        console.error('Error fetching rewards:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, []);

  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={4} style={{ marginTop: "100px", marginBottom: "100px" }}>
        {rewards.map((reward) => (
          <Grid item xs={6} sm={6} md={4} key={reward.reward_id}>
            <Card style={{ height: "350px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardMedia
                component="img"
                height="140"
                image={reward.imageUrl || 'https://via.placeholder.com/140'}
                alt={reward.name}
              />
              <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
                <Typography variant="h5" component="div" gutterBottom style={{ flexGrow: 1 }}>
                  {reward.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ flexGrow: 1 }}>
                  {reward.desc}
                </Typography>
                <Typography variant="h6" component="div" style={{ marginTop: '1rem' }}>
                  Points: {reward.point_cost}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LoyaltyRewards;
