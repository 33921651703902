import "./selection.css"
import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import OrderButton from "./OrderButton";
import TruncatedText from "../../../../molecules/TruncateText";
import ShrinkMenuImage from "../../../../molecules/ShrinkMenuImage";


const MAX_WIDTH = 194;

export type Props = {
  onPress: () => Promise<boolean>;
  notAvailable?: boolean;
  name: string;
  iconUrl?: string;
  description?: string;
  priceStr?: string;
  discountPriceStr?: string;
  onStoreClosed?: () => void;
};

function checkAllergy(name:any) {

  if(name == 'P-Nut') {    
    return (
      <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "30px"
      }}
    >
      <Box
        component="img"
        src="/images/menu/DIARY-ICON.png"
        alt="Diary Icon"
        sx={{ width: 50, height: 50, marginBottom: 2 }}
      />
      <Box
        component="img"
        src="/images/menu/PEANUT-ICON.png"
        alt="Peanut Icon"
        sx={{ width: 50, height: 50 }}
      />
    </Box>
    )

  }

  if(name == 'Pistach') {
    return (
      <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "30px"
      }}
    >
      <Box
        component="img"
        src="/images/menu/DIARY-ICON.png"
        alt="Diary Icon"
        sx={{ width: 50, height: 50, marginBottom: 2 }}
      />
      <Box
        component="img"
        src="/images/menu/PISTACHIO-ICON.png"
        alt="Pistachio Icon"
        sx={{ width: 50, height: 50 }}
      />
    </Box>
    )

    
  }

  
}

const SelectionItemView = ({
  iconUrl,
  name,
  description,
  priceStr,
  discountPriceStr,
  onPress,
  notAvailable,
  onStoreClosed
}: Props) => {

  const onPressSelectionItem = async () => {
    if ( notAvailable ) {
      return false
    }
    return onPress()
  };
  
  const priceComponent = useMemo(() => {
    const discountStrAdjusted = priceStr == '' ? "Free" : discountPriceStr;
    if  ( discountStrAdjusted ) return <Box style={{display:'flex', justifyContent: 'center', alignItems: "center"}}>
      <Typography style={{
      color: '#FF00B8',
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '600',
      wordWrap: 'break-word',
      textAlign: "center",
      lineHeight: "13.76px",
      marginRight: 2
    }}>{discountPriceStr}</Typography>
    <Typography style={{
      marginLeft: 2,
      color: '#EE72F1',
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: '600',
      wordWrap: 'break-word',
      textAlign: "center",
      lineHeight: "12.04px",
      textDecoration: 'line-through'
    }}>{priceStr}</Typography>
    </Box>;
    
    return <Typography style={{
      color: '#FF00B8',
      fontSize: '16',
      fontFamily: 'Inter',
      fontWeight: '600',
      wordWrap: 'break-word',
      textAlign: "center",
      lineHeight: "13.76px"
    }}>{priceStr}</Typography>;
  }, [discountPriceStr, priceStr])

  
  return (  
    <div  style={{
      textAlign: "center", overflow: "hidden",  border: "1px solid #4712B1",
      width: MAX_WIDTH, height: 322,
      display: "flex",
      justifyContent:"space-between",
      flexDirection: "column",
      backgroundColor: "#DECDFF"}}>
      <div style={{ width: "100%"}}>
        <Typography style={{
          color: '#FF00B8',
          fontSize: 24,
          fontFamily: 'BN Dime Display',
          fontWeight: '400',
          wordWrap: 'break-word',
          textAlign: "center",
          marginTop:8,
        }}>{name?.toUpperCase()}</Typography>
      </div>
      <ShrinkMenuImage
        description={description ?? ''}
        iconUrl={iconUrl ?? ''}
        name={name}
        checkAllergy={checkAllergy}
      >
      </ShrinkMenuImage>

      <div style={{height: '100px', width: "100%"}}>
        {priceComponent}
        <div style={{width : "100%", display: "flex", justifyContent: "center", marginTop: 16, marginBottom: 16}}>
          <OrderButton onOrder={onPressSelectionItem} notAvailable={notAvailable} onStoreClosed={onStoreClosed}/>
        </div>
      </div>
    </div>
      )
};

export default SelectionItemView;
